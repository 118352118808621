<script>
export default {
	name: "Loader"
}
</script>

<template>
	<div class="text-center mb-3 d-flex justify-content-between">
		<b-spinner
			variant="secondary"
			style="width: 3rem; height: 3rem;position: absolute;top: 50%; left: 50%;" label="Large Spinner"
		></b-spinner>
	</div>
</template>

<style scoped lang="scss">

</style>