<script>
import NavBarLeft from "@/components/NavBarLeft.vue";

export default {
  name: "LayoutDefault",
  components: {NavBarLeft}
}
</script>

<template>
  <div class="layout-wrapper d-lg-flex">
    <NavBarLeft />
    <div class="w-100">
      <slot name="default" />
    </div>
  </div>
</template>

<style scoped lang="scss">

</style>