<script>
import LayoutDefault from "@/views/layouts/LayoutDefault.vue";
import {profileComputed, profileMethods, notificationMethods} from "@/state/helpers";
import Loader from "@/components/Partials/Loader.vue";
export default {
	name: "Admin",
	components: {
		Loader,
		LayoutDefault
	},
	data() {
		return {
			showDeleteModal: false,
			activeUserId: null,
			currentPage: 1,
			fields: ['email', 'registration_date', 'subscription', 'chats_1_$', 'status', 'total_monthly_$', 'total_$', 'actions']
		}
	},
	computed: {
		...profileComputed,
		notification() {
			return this.$store ? this.$store.state.notification : null;
		},
		notificationAutoCloseDuration() {
			return this.$store && this.$store.state.notification ? 5 : 0;
		},
		perPage() {
			return this?.adminAllUsersInfo?.usersPerPage
		},
		rows() {
			return this?.adminAllUsersInfo?.totalUsers
		},
		allUsersInfo() {
			return this?.adminAllUsersInfo?.users?.map((item) => {
				return {
					id: item.id,
					email: item.email,
					status: item.status,
					registration_date: this.formatDate(item.created_at),
					total_$: item.total_amount,
					chats_1_$: item.total_daily,
					subscription: item.monthly_subscriptions_count + ' month(s)',
					subscriptions: item.subscription,
					total_monthly_$: item.total_monthly,
				}
			})
		}
	},
	watch: {
		currentPage(newPage) {
			this.$store.dispatch("profile/getAdminListOfAllUsers",newPage);
		}
	},
	methods: {
		...profileMethods,
		...notificationMethods,
		formatDate(dateString) {
			const date = new Date(dateString);
			const month = String(date.getMonth() + 1).padStart(2, '0');
			const day = String(date.getDate()).padStart(2, '0');
			const year = date.getFullYear();
			return `${month}/${day}/${year}`;
		},
		getStatusClass(status) {
			if(status === 'Active'){
				return 'status-green'
			} else if(status === 'Active daily') {
				return 'status-green'
			} else {
				return 'status-yellow'
			}
		},
		canDeleteUserAsAdmin(id) {
			this.showDeleteModal = true;
			this.activeUserId = id
		},
		deleteUserAsAdmin(){
			const delObj = {
				activeUserId: this.activeUserId,
				currentPage:this.currentPage
			}
			this.$store.dispatch("profile/deleteUserAsAdmin", delObj);
			this.showDeleteModal = false;
		},
		changeSubscriptionStatus(userId, id, subscriptionId, currentPage) {
			this.$store.dispatch("profile/updateUserSubscriptionStatusAsAdmin", {userId, id, subscriptionId, currentPage});
		}
	},
	mounted() {
		this.$store.dispatch("profile/getAdminListOfAllUsers",this.currentPage);
	}
}
</script>

<template>
	<LayoutDefault>
		<div class="admin-bl">
			<div class="px-4 pt-4">
				<div class="d-flex justify-content-between align-items-center">
					<h4 class="mb-0">Admin</h4>
				</div>
			</div>
			<div class="p-4">
				<div class="card">
					<Loader v-if="!allUsersInfo"/>
					<div v-else class="card-body p-4 filter-table">
						<Loader v-if="loading"/>
						<b-table
							v-else
							:items="allUsersInfo"
							:fields="fields"
							responsive="sm"
							class="text-center">
							<template v-slot:cell(status)="{ item }">
										<span
											:class="getStatusClass(item.status)"
										>{{item.status}}</span>
							</template>
							<template v-slot:cell(actions)="{ item }">
								<b-dropdown toggle-class="nav-btn" variant="white" right>
									<template v-slot:button-content>
										<i class="ri-more-fill"></i>
									</template>
									<b-dropdown-item
										v-if="item.subscriptions && item.subscriptions.subscription_plan !== 'Daily' &&
										item.status !== 'Active' && item.subscriptions.can_activate"
										class="d-block user-profile-show"
										@click="changeSubscriptionStatus(item.id, 1, item.subscriptions.id, currentPage)"
									>
										Activate
										<i class="ri-checkbox-circle-line float-right text-muted"></i>
									</b-dropdown-item>
									<b-dropdown-item
										v-if="item.subscriptions && item.subscriptions.subscription_plan !== 'Daily'"
										class="d-block user-profile-show"
										@click="changeSubscriptionStatus(item.id, 2, item.subscriptions.id, currentPage)"
									>
										Pause
										<i class="ri-pause-circle-line float-right text-muted"></i>
									</b-dropdown-item>
									<b-dropdown-item
										v-if="item.subscriptions && item.subscriptions.subscription_plan !== 'Daily'"
										class="d-block user-profile-show"
										@click="changeSubscriptionStatus(item.id, 3, item.subscriptions.id, currentPage)"
									>
										Cancel
										<i class="ri-close-circle-line float-right text-muted"></i>
									</b-dropdown-item>
									<b-dropdown-item
										class="d-block user-profile-show"
										@click="canDeleteUserAsAdmin(item.id)"
									>
										Delete user
										<i class="ri-delete-bin-6-line float-right text-muted"></i>
									</b-dropdown-item>
								</b-dropdown>
							</template>
						</b-table>
						<b-pagination
							v-model="currentPage"
							:total-rows="rows"
							:per-page="perPage"
							first-text="First"
							prev-text="Prev"
							next-text="Next"
							last-text="Last"
							class="m-auto"
						></b-pagination>
						<b-modal
							v-model="showDeleteModal"
							title="Remove user"
							hide-footer
						>
							<div>
								<p>Are you sure you want to remove this user?</p>
							</div>
							<b-alert
								:variant="notification.type"
								class="mt-3"
								v-if="notification.message"
								:show="notificationAutoCloseDuration"
								dismissible
							>{{ notification.message }}
							</b-alert>
							<div class="text-right pt-3 mt-3">
								<b-button variant="link" @click="showDeleteModal = false">
									Cancel
								</b-button>
								<b-button variant="primary" class="ml-1" @click="deleteUserAsAdmin">
									Delete
								</b-button>
							</div>
						</b-modal>
					</div>
				</div>
			</div>
		</div>
	</LayoutDefault>
</template>

<style scoped lang="scss">
.admin-bl{
	margin-left: 75px;
	margin-bottom: 35px;
}
</style>